import axios from 'axios';

const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND,
})

const api = (() => {
    const get = (url, config, token) => {
        if (token == null) {
            token = ""
        }
        return client.get(url, {...config, validateStatus: (status) => {return status < 500}, headers: {'Authorization': token}})
    }

    const post = (url, payload, config, token) => {
        if (token == null) {
            token = ""
        }
        return client.post(url, payload, {...config, validateStatus: (status) => {return status < 500}, headers: {'Authorization': token}})
    }

    const put = (url, payload, config, token) => {
        if (token == null) {
            token = ""
        }
        return client.put(url, payload, {...config, validateStatus: (status) => {return status < 500}, headers: {'Authorization': token}})
    }

    const del = (url, config, token) => {
        if (token == null) {
            token = ""
        }
        return client.delete(url, {...config, validateStatus: (status) => {return status < 500}, headers: {'Authorization': token}})
    }

    return {
        get, post, put, del
    }
})()

export default api
