import React from 'react'
import {Tab, Table, TableCell} from "semantic-ui-react";

class UserStatDetail extends React.Component {

    render() {
        const renderList = this.props.data ? Object.keys(this.props.data).map((key) => {
            const tableCell = this.props.isTotal ? null : <TableCell>{key}</TableCell>
            return (
                <Table.Row key={key}>
                    {tableCell}
                    <TableCell>{Number(this.props.data[key]['uma']).toFixed(1)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['average_uma']).toFixed(2)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['score']).toFixed(0)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['average_score']).toFixed(0)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['victory_point']).toFixed(0)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['average_vp']).toFixed(2)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['average_rank']).toFixed(2)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['first_ratio']).toFixed(1)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['half_ratio']).toFixed(1)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['last_ratio']).toFixed(1)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['first_count'].toFixed(0))}</TableCell>
                    <TableCell>{Number(this.props.data[key]['second_count']).toFixed(0)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['third_count']).toFixed(0)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['fourth_count']).toFixed(0)}</TableCell>
                    <TableCell>{Number(this.props.data[key]['match_count']).toFixed(0)}</TableCell>
                </Table.Row>
            )
        }) : null
        const headerCell = this.props.isTotal ? null : <Table.HeaderCell>{this.props.columnName}</Table.HeaderCell>
        return (
            <div>
                <h3>{this.props.title}</h3>
                <Table celled unstackable padded>
                    <Table.Header>
                        <Table.Row>
                            {headerCell}
                            <Table.HeaderCell>우마</Table.HeaderCell>
                            <Table.HeaderCell>평우마</Table.HeaderCell>
                            <Table.HeaderCell>득점</Table.HeaderCell>
                            <Table.HeaderCell>평득점</Table.HeaderCell>
                            <Table.HeaderCell>승점</Table.HeaderCell>
                            <Table.HeaderCell>평승점</Table.HeaderCell>
                            <Table.HeaderCell>평순위</Table.HeaderCell>
                            <Table.HeaderCell>1위율</Table.HeaderCell>
                            <Table.HeaderCell>연대율</Table.HeaderCell>
                            <Table.HeaderCell>4위율</Table.HeaderCell>
                            <Table.HeaderCell>1위</Table.HeaderCell>
                            <Table.HeaderCell>2위</Table.HeaderCell>
                            <Table.HeaderCell>3위</Table.HeaderCell>
                            <Table.HeaderCell>4위</Table.HeaderCell>
                            <Table.HeaderCell>국수</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {renderList}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

export default UserStatDetail
