import React from 'react'
import "../../style.css"
import {Link, withRouter} from "react-router-dom";
import api from "../../apis/api";
import _ from "lodash";
import {Button, Form, Grid, Header, Input, Table, TableCell, TableRow} from "semantic-ui-react";
import UserStatDetail from "./UserStatDetail";
import {PieChart} from "react-minimal-pie-chart";
import UserStatSummary from "./UserStatSummary";

class UserRelativeStat extends React.Component {
    state = {
        name: "",
        fixedName: "",
        start_date: "",
        end_date: "",
        num_games: "",
        stat: {
            monthly: {},
            yearly: {},
            total: {},
        },
        relative: {}
    }

    fetchUser = async () => {
        const result = await api.get(`/user-stats/${this.props.match.params.name}`, {})
        if (result.status === 400) {
            this.props.history.push("/")
        }
        this.setState({stat: result.data})
    }

    fetchRelativeStats = async (name, start_date, end_date, num_games) => {
        let params = {
            opponent: name
        }
        if (start_date) {
            params.start_date = start_date
        }
        if (end_date) {
            params.end_date = end_date
        }
        if (num_games) {
            params.num_games = num_games
        }

        const result = await api.get(`/relative-stats/${this.props.match.params.name}`, {
            params: params
        })
        this.setState({fixedName: name})
        if (result.status === 400) {
            alert(result.data)
            this.setState({relative: null})
        } else {
            this.setState({relative: result.data})
        }
    }

    componentDidMount() {
        this.fetchUser()
    }

    onNameChange = e => {
        this.setState({name: e.target.value})
    }

    onStartDateChange = e => {
        this.setState({start_date: e.target.value})
    }

    onEndDateChange = e => {
        this.setState({end_date: e.target.value})
    }

    onNumGamesChange = e => {
        this.setState({num_games: e.target.value})
    }

    onClick = () => {
        this.fetchRelativeStats(
            this.state.name,
            this.state.start_date,
            this.state.end_date,
            this.state.num_games
        )
    }

    render() {
        const pieChartData = !_.isEmpty(this.state.stat.total) ? [
            {title: '1위', value: this.state.stat.total.first_count, color: '#FFAAA7'},
            {title: '2위', value: this.state.stat.total.second_count, color: '#FFD3B4'},
            {title: '3위', value: this.state.stat.total.third_count, color: '#D5ECC2'},
            {title: '4위', value: this.state.stat.total.fourth_count, color: '#98DDCA'},
        ] : []

        return (
            <div className={"fix-width"}>
                <h1>{`${this.props.match.params.name} 님의 상대 기록`}</h1>
                <div className="ui two column very relaxed grid">
                    <div className="column">
                        <PieChart className={"fix-width-small"}
                                  data={pieChartData}
                                  label={({dataEntry}) => dataEntry.value}
                                  labelStyle={(index) => ({
                                      fill: pieChartData[index].color,
                                      fontSize: '5px',
                                      fontFamily: 'sans-serif',
                                  })}
                                  radius={38}
                                  labelPosition={112}
                                  startAngle={270}
                        />
                    </div>
                    <div className="column">
                        <UserStatSummary
                            isPersonal
                            data={this.state.stat.total}
                            title={`총 국수 : ${this.state.stat.total.match_count}`}
                        />
                        <Link to={`/users/${this.props.match.params.name}`} className="item">
                            <button className={"ui basic black button"}>개인 기록 보러가기!!</button>
                        </Link>
                    </div>
                </div>
                <Grid verticalAlign='middle'>
                    <Grid.Row>
                        <Form>
                            <Form.Group inline>
                                <Header as="h3" style={{marginBottom: 0, marginLeft: '10px'}}>
                                    비교할 상대 이름
                                </Header>
                                <Form.Field>
                                    <Input
                                        type="text"
                                        value={this.state.name}
                                        onChange={this.onNameChange}
                                        placeholder="상대 이름을 입력하세요"
                                        style={{marginLeft: '120px'}}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group inline>
                                <Header as="h3" style={{marginBottom: 0, marginLeft: '10px'}}>
                                    언제부터? (비워두면 처음부터)
                                </Header>
                                <Form.Field>
                                    <Input
                                        type="text"
                                        name="start_date"
                                        value={this.state.start_date}
                                        onChange={this.onStartDateChange}
                                        placeholder="yyyy/mm/dd 형태"
                                        style={{marginLeft: '24px'}}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group inline>
                                <Header as="h3" style={{marginBottom: 0, marginLeft: '10px'}}>
                                    언제까지? (비워두면 끝까지)
                                </Header>
                                <Form.Field>
                                    <Input
                                        type="text"
                                        name="end_date"
                                        value={this.state.end_date}
                                        onChange={this.onEndDateChange}
                                        placeholder="yyyy/mm/dd 형태"
                                        style={{marginLeft: '39px'}}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group inline>
                                <Header as="h3" style={{marginBottom: 0, marginLeft: '10px'}}>
                                    최대 몇 판? (비워두면 전부)
                                </Header>
                                <Form.Field>
                                    <Input
                                        type="text"
                                        name="num_games"
                                        value={this.state.num_games}
                                        onChange={this.onNumGamesChange}
                                        placeholder="숫자로 입력"
                                        style={{marginLeft: '48px'}}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Field>
                                <Button basic onClick={this.onClick} style={{marginLeft: '10px'}}>
                                    비교!
                                </Button>
                            </Form.Field>
                        </Form>
                    </Grid.Row>
                </Grid>
                {
                    !_.isEmpty(this.state.relative) ? (
                        <React.Fragment>
                            <h2/>
                            <h2>총 국수: {this.state.relative.stat.match_count}</h2>
                            <div className="ui two column very relaxed grid">
                                <div className="column">
                                    <UserStatSummary
                                        data={this.state.relative.stat}
                                        title={`${this.props.match.params.name} 님의 기록`}
                                    />
                                </div>
                                <div className="column">
                                    <UserStatSummary
                                        data={this.state.relative.opponent_stat}
                                        title={`${this.state.fixedName} 님의 기록`}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    ) : null
                }
                <h1/>
            </div>
        )
    }
}

export default withRouter(UserRelativeStat)
