import React, {forwardRef} from "react"
import api from "../../apis/api"
import {withRouter} from "react-router-dom";
import GameForm from "./GameForm";
import "../../style.css"
import {compose} from "redux";
import {connect} from "react-redux";
import {signOut} from "../../actions";
import DatePicker from "react-datepicker";

class GameCreate extends React.Component {
    state = {date: new Date()}

    onSubmit = async game => {
        const response = await api.post('/games', {'result': game, date: this.state.date}, {}, this.props.token)
        if (response.status === 401) {
            alert("다시 로그인 해주세요")
            this.props.signOut()
        }
        if (response.status !== 200) {
            alert("등록 실패!")
        } else if (response.data === "Success") {
            this.props.history.push("/daily")
        } else {
            alert(response.data)
        }
    }

    render() {
        const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
            <button className="ui button black basic margin-bottom" onClick={onClick} ref={ref}>
                {value}
            </button>
        ))

        return (
            <div className={"fix-width"}>
                <h3>게임 등록</h3>
                <DatePicker
                    dateFormat="yyyy년 MM월 dd일"
                    selected={this.state.date}
                    onChange={(date) => this.setState({date: date})}
                    customInput={<ExampleCustomInput/>}
                />

                <GameForm onSubmit={this.onSubmit}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(GameCreate)
