import React from 'react'
import {connect} from "react-redux";
import {signIn} from "../actions";
import "../style.css"
import api from "../apis/api";

class Auth extends React.Component {
    state = {inputs: {id: '', password: ''}}

    onInputChange = event => {
        this.setState({inputs: {...this.state.inputs, [event.target.name]: event.target.value}})
    }

    onFormSubmit = async event => {
        event.preventDefault()
        const response = await api.post('/auth', {
            'id': this.state.inputs.id,
            'password': this.state.inputs.password
        })
        if (response.status === 200 && response.data.success === true) {
            this.props.dispatch(signIn(response.data.role, response.data.token))
        } else {
            alert("로그인 실패!")
        }
    }

    render() {
        return (
            <form onSubmit={this.onFormSubmit} className={"ui form fix-width"}>
                <label>ID</label>
                <input className={"ui input margin-bottom"}
                       type={"text"}
                       name={"id"}
                       value={this.state.id}
                       onChange={this.onInputChange}
                />
                <label>Password</label>
                <input className={"ui input margin-bottom"}
                       type={"password"}
                       name={"password"}
                       value={this.state.id}
                       onChange={this.onInputChange}
                />

                <button className={"ui button red"} onSubmit={this.onFormSubmit}>로그인</button>
            </form>
        )
    }
}

const mapStateToProps = (state) => {
    return {isSignedIn: state.auth.isSignedIn}
}

export default connect(mapStateToProps)(Auth)
