import { SIGN_IN, SIGN_OUT } from "./types";

export const signIn = (role, token) => {
    if (token == null) {
        token = ""
    }
    return {
        type: SIGN_IN,
        payload: {role: role, token: token}
    }
}

export const signOut = () => {
    return {
        type: SIGN_OUT
    }
}
