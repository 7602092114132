import React, {forwardRef, useEffect, useState} from 'react'
import "../../style.css"
import DatePicker from "react-datepicker";
import api from "../../apis/api";
import calculateStats from "../../util";
import exampleReducer from "../../reducers/exampleReducer";
import RecordTable from "./RecordTable";

const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
    <button className="ui button black basic margin-bottom" onClick={onClick} ref={ref}>
        {value}
    </button>
))

const WeeklyRecord = () => {
    const [games, setGames] = useState([])
    const [stats, setStats] = useState([])
    const [date, setDate] = useState(new Date())
    const [start, setStart] = useState(new Date())
    const [end, setEnd] = useState(new Date())
    const [listStats, setListStats] = useState([])

    const [state, dispatch] = React.useReducer(exampleReducer, {
        column: null,
        data: listStats,
        direction: null,
    })
    const {column, data, direction} = state

    const fetchGames = async () => {
        const results = await api.get('/games/weekly', {
            params: {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            }
        })
        if (results.status === 200) {
            setGames(results.data.games)
            setStart(new Date(results.data.d_start))
            const d_end = new Date(results.data.d_end)
            d_end.setDate(d_end.getDate() - 1)
            setEnd(d_end)
        } else {
            setGames([])
            setStart(new Date())
            setEnd(new Date())
        }
    }

    useEffect(() => {
        fetchGames()
    }, [date])

    useEffect(() => {
        setStats(calculateStats(games))
    }, [games])

    useEffect(() => {
        setListStats(stats.map(stat => {
            const [name, {
                uma,
                score,
                victory_point,
                first_count,
                second_count,
                third_count,
                fourth_count,
                match_count
            }] = stat
            return (
                {
                    name: name,
                    uma:  Number(uma).toFixed(1),
                    average_uma: Number(uma / match_count).toFixed(2),
                    score: Number(score).toFixed(0),
                    average_score: Number(score / match_count).toFixed(0),
                    victory_point: Number(victory_point).toFixed(0),
                    average_vp: Number(victory_point / match_count).toFixed(2),
                    average_rank: Number((first_count + second_count * 2 + third_count * 3 + fourth_count * 4) / match_count).toFixed(2),
                    first_ratio: Number(first_count / match_count * 100).toFixed(1),
                    half_ratio: Number((first_count + second_count) / match_count * 100).toFixed(1),
                    last_ratio: Number(fourth_count / match_count * 100).toFixed(1),
                    first_count: first_count,
                    second_count: second_count,
                    third_count: third_count,
                    fourth_count: fourth_count,
                    match_count: match_count
                }
            )
        }))
    }, [stats])

    useEffect(() => {
        dispatch({type: 'CHANGE_SORT', column: column, data: listStats})
    }, [listStats])

    return (
        <div className={"fix-width"}>
            <DatePicker
                dateFormat="yyyy년 MM월 dd일"
                selected={date}
                onChange={(date) => setDate(date)}
                customInput={<ExampleCustomInput/>}
            />
            <h3>주간 랭킹</h3>
            <p>시작: {`${start.getFullYear()}년 ${start.getMonth() + 1}월 ${start.getDate()}`}일</p>
            <p>종료: {`${end.getFullYear()}년 ${end.getMonth() + 1}월 ${end.getDate()}`}일</p>
            <RecordTable column={column} data={data} direction={direction} dispatch={dispatch}/>
        </div>
    )
}

export default WeeklyRecord
