
const sort_object = obj => {
    const items = Object.keys(obj).map(function (key) {
        return [key, obj[key]];
    });
    items.sort(function (first, second) {
        return second[1]['uma'] - first[1]['uma']
    });
    return items
}

const calculateStats = (games) => {
    const users = new Set()
    const stats = {}

    games.forEach(game => {
        game.result.forEach(individual => {
            if (!users.has(individual.name)) {
                users.add(individual.name)
                stats[individual.name] = {
                    'uma': 0,
                    'score': 0,
                    'victory_point': 0,
                    'star': 0,
                    'match_count': 0,
                    'first_count': 0,
                    'second_count': 0,
                    'third_count': 0,
                    'fourth_count': 0
                }
            }
            stats[individual.name]['uma'] = Number((stats[individual.name]['uma'] + individual.uma).toFixed(1))
            stats[individual.name]['score'] += (individual.score - 25000)
            stats[individual.name]['victory_point'] += individual.victory_point
            stats[individual.name]['star'] += individual.star
            stats[individual.name]['match_count'] += 1
            stats[individual.name]['first_count'] += individual.first_count
            stats[individual.name]['second_count'] += individual.second_count
            stats[individual.name]['third_count'] += individual.third_count
            stats[individual.name]['fourth_count'] += individual.fourth_count
        })
    })

    return sort_object(stats)
}

export default calculateStats
