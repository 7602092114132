
const monthOption = [
    {
        key: '1',
        text: '01월',
        value: 1,
    },
    {
        key: '2',
        text: '02월',
        value: 2,
    },
    {
        key: '3',
        text: '03월',
        value: 3,
    },
    {
        key: '4',
        text: '04월',
        value: 4,
    },
    {
        key: '5',
        text: '05월',
        value: 5,
    },
    {
        key: '6',
        text: '06월',
        value: 6,
    },
    {
        key: '7',
        text: '07월',
        value: 7,
    },
    {
        key: '8',
        text: '08월',
        value: 8,
    },
    {
        key: '9',
        text: '09월',
        value: 9,
    },
    {
        key: '10',
        text: '10월',
        value: 10,
    },
    {
        key: '11',
        text: '11월',
        value: 11,
    },
    {
        key: '12',
        text: '12월',
        value: 12,
    },
]

const yearOption = [
    {
        key: '2021',
        text: '2021년',
        value: 2021,
    },
    {
        key: '2022',
        text: '2022년',
        value: 2022,
    },
    {
        key: '2023',
        text: '2023년',
        value: 2023,
    },
    {
        key: '2024',
        text: '2024년',
        value: 2024,
    },
]

export {monthOption, yearOption}
