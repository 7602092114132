import React from "react"
import {Table, TableCell, TableRow} from "semantic-ui-react";
import {Link} from "react-router-dom";
import "../../style.css"

const RecordTable = ({column, data, direction, dispatch}) => {
    return (
        <Table sortable celled unstackable>
            <Table.Header className={"sticky"}>
                <Table.Row>
                    <Table.HeaderCell>
                        순위
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        닉네임
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'uma' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'uma'})}
                    >
                        우마
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'average_uma' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'average_uma'})}
                    >
                        평우마
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'score' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'score'})}
                    >
                        득점
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'average_score' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'average_score'})}
                    >
                        평득점
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'victory_point' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'victory_point'})}
                    >
                        승점
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'average_vp' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'average_vp'})}
                    >
                        평승점
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'average_rank' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'average_rank'})}
                    >
                        평순위
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'first_ratio' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'first_ratio'})}
                    >
                        1위율
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'half_ratio' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'half_ratio'})}
                    >
                        연대율
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'last_ratio' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'last_ratio'})}
                    >
                        4위율
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'first_count' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'first_count'})}
                    >
                        1위
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        2위
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        3위
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        4위
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'match_count' ? direction : null}
                        onClick={() => dispatch({type: 'CHANGE_SORT', column: 'match_count'})}
                    >
                        국수
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.map((d, index) => (
                    <TableRow key={d.name}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                            <Link to={`/users/${d.name}`}>
                                {d.name}
                            </Link>
                        </TableCell>
                        <TableCell>{d.uma}</TableCell>
                        <TableCell>{d.average_uma}</TableCell>
                        <TableCell>{d.score}</TableCell>
                        <TableCell>{d.average_score}</TableCell>
                        <TableCell>{d.victory_point}</TableCell>
                        <TableCell>{d.average_vp}</TableCell>
                        <TableCell>{d.average_rank}</TableCell>
                        <TableCell>{d.first_ratio}</TableCell>
                        <TableCell>{d.half_ratio}</TableCell>
                        <TableCell>{d.last_ratio}</TableCell>
                        <TableCell>{d.first_count}</TableCell>
                        <TableCell>{d.second_count}</TableCell>
                        <TableCell>{d.third_count}</TableCell>
                        <TableCell>{d.fourth_count}</TableCell>
                        <TableCell>{d.match_count}</TableCell>
                    </TableRow>
                ))}
            </Table.Body>
        </Table>
    )
}

export default RecordTable
