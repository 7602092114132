import React from "react"
import api from "../../apis/api";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {signOut} from "../../actions";

class UserForm extends React.Component {
    state = {
        user: {
            name: '', phone: '', month: '', day: ''
        }
    }

    onInputChange = (event) => {
        const user = {...this.state.user}
        user[event.target.name] = event.target.value
        this.setState({user: user})
    }

    onFormSubmit = async (event) => {
        event.preventDefault()
        const status = this.props.onSubmit(this.state.user)
        if (await status === true) {
            this.setState({user: {name: '', phone: '', month: '', day: ''}})
        }
    }

    fetchUser = async () => {
        const result = await api.get(`/users/${this.props.match.params.name}`, {}, this.props.token)
        if (result.status === 401) {
            alert("다시 로그인 해주세요")
            this.props.signOut()
        } else if (result.status === 400) {
            this.props.history.push("/")
        }
        this.setState({user: result.data})
    }

    componentDidMount() {
        if (this.props.name) {
            this.fetchUser()
        }
    }

    render() {
        return (
            <div className={"fix-width"}>
                <form onSubmit={this.onFormSubmit} className={"ui form"}>
                    <div className={"field"}>
                        <div className={"four fields"}>
                            <div className={"field"}>
                                <input
                                    type={"text"}
                                    name={"name"}
                                    placeholder={"닉네임"}
                                    value={this.state.user.name}
                                    onChange={this.onInputChange}
                                />
                            </div>
                            <div className={"field"}>
                                <input
                                    type={"text"}
                                    name={"phone"}
                                    placeholder={"연락처"}
                                    value={this.state.user.phone}
                                    onChange={this.onInputChange}
                                />
                            </div>
                            <div className={"field"}>
                                <input
                                    type={"text"}
                                    name={"month"}
                                    placeholder={"생일 (월)"}
                                    value={this.state.user.month}
                                    onChange={this.onInputChange}
                                />
                            </div>
                            <div className={"field"}>
                                <input
                                    type={"text"}
                                    name={"day"}
                                    placeholder={"생일 (일)"}
                                    value={this.state.user.day}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <button className={"ui button primary"} onSubmit={this.onFormSubmit}>등록</button>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(UserForm)
