import React from "react"
import {withRouter} from "react-router-dom";
import api from "../../apis/api";
import {connect} from "react-redux";
import {compose} from "redux";
import {signOut} from "../../actions";

class GameForm extends React.Component {
    state = {
        game: [
            {name: '', score: '', special: ''},
            {name: '', score: '', special: ''},
            {name: '', score: '', special: ''},
            {name: '', score: '', special: ''}
        ]
    }

    onInputChange = (event, idx) => {
        const records = [...this.state.game]
        const record = {...records[idx]}
        record[event.target.name] = event.target.value
        records[idx] = record
        this.setState({game: records})
    }

    filterInt = value => {
        if (/^(0|-?[1-9][0-9]*)$/.test(value))
            return Number(value);
        return NaN;
    }

    onFormSubmit = async (event) => {
        event.preventDefault()
        let flag = false
        for (let idx = 0; idx < 4; idx++) {
            if (this.state.game[idx].name === '') {
                flag = true
                break
            }
            if (this.state.game[idx].score === '') {
                flag = true
                break
            }
            let score = this.filterInt(this.state.game[idx].score)
            if (Number.isNaN(score)) {
                flag = true
                break
            }
            if (idx > 0 && score > this.filterInt(this.state.game[idx - 1].score)) {
                flag = true
                break
            }
        }
        if (flag) {
            alert("항목들을 제대로 입력해주세요")
            return
        }
        this.props.onSubmit(this.state.game)
    }

    renderIndividual = (idx) => {
        return (
            <React.Fragment>
                <label>{idx + 1}등</label>
                <div className={"three fields"}>
                    <div className={"field"}>
                        <input
                            type={"text"}
                            name={"name"}
                            placeholder={"닉네임"}
                            value={this.state.game[idx].name}
                            onChange={(e) => this.onInputChange(e, idx)}
                        />
                    </div>
                    <div className={"field"}>
                        <input
                            type={"text"}
                            name={"score"}
                            placeholder={"점수"}
                            value={this.state.game[idx].score}
                            onChange={(e) => this.onInputChange(e, idx)}
                        />
                    </div>
                    <div className={"field"}>
                        <input
                            type={"text"}
                            name={"special"}
                            placeholder={"기타 (역만 등)"}
                            value={this.state.game[idx].special}
                            onChange={(e) => this.onInputChange(e, idx)}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    fetchGame = async () => {
        const result = await api.get(`/games/${this.props.match.params.id}`, {}, this.props.token)
        if (result.status === 401) {
            alert("다시 로그인 해주세요")
            this.props.signOut()
        } else if (result.status === 400) {
            this.props.history.push("/")
        }
        this.setState({game: result.data.result})
    }

    componentDidMount() {
        if (this.props.gameId) {
            this.fetchGame()
        }
    }

    render() {
        return (
            <form onSubmit={this.onFormSubmit} className={"ui form"}>
                <div className={"field"}>
                    {this.renderIndividual(0)}
                    {this.renderIndividual(1)}
                    {this.renderIndividual(2)}
                    {this.renderIndividual(3)}
                </div>
                <button className={"ui button primary"} onSubmit={this.onFormSubmit}>등록</button>
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(GameForm)
