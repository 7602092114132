import React from 'react'
import {Link} from "react-router-dom";

class UserDetail extends React.Component {
    render() {
        return (
            <div className={"ui card"} key={this.props.user.name}>
                <div className={"content"}>
                    <div className={"four wide column middle aligned"}>
                        <h3>{this.props.user.name} ({this.props.user.month}/{this.props.user.day})</h3>
                        <p>{this.props.user.phone}</p>
                    </div>

                    <Link
                        className={"ui right floated primary button"}
                        to={`/users/edit/${this.props.user.name}`}
                    >
                        수정
                    </Link>
                </div>
            </div>
        )
    }
}

export default UserDetail
