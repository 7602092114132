import React from 'react'
import "../../style.css"
import {Link, withRouter} from "react-router-dom";
import api from "../../apis/api";
import {Table, TableCell, TableRow} from "semantic-ui-react";
import {PieChart} from "react-minimal-pie-chart";
import UserStatDetail from "./UserStatDetail";
import _ from "lodash"
import UserStatSummary from "./UserStatSummary";

class UserStat extends React.Component {
    state = {
        stat: {
            monthly: {},
            yearly: {},
            total: {},
        }
    }

    fetchUser = async () => {
        const result = await api.get(`/user-stats/${this.props.match.params.name}`, {})
        if (result.status === 400) {
            this.props.history.push("/")
        }
        this.setState({stat: result.data})
    }

    componentDidMount() {
        this.fetchUser()
    }

    render() {
        const pieChartData = !_.isEmpty(this.state.stat.total) ? [
            {title: '1위', value: this.state.stat.total.first_count, color: '#FFAAA7'},
            {title: '2위', value: this.state.stat.total.second_count, color: '#FFD3B4'},
            {title: '3위', value: this.state.stat.total.third_count, color: '#D5ECC2'},
            {title: '4위', value: this.state.stat.total.fourth_count, color: '#98DDCA'},
        ] : []

        const renderMonthlyList = this.state.stat.monthly ? Object.keys(this.state.stat.monthly).map((key) => {
            return (
                <React.Fragment key={key}>
                    <UserStatDetail title={`${key}년 월별 기록`} columnName={"월"} data={this.state.stat.monthly[key]}/>
                    <h1></h1>
                </React.Fragment>
            )
        }) : null

        const renderYearlyStat = !_.isEmpty(this.state.stat.yearly) ? (
            <React.Fragment>
                <UserStatDetail title={"연도별 기록"} columnName={"년"} data={this.state.stat.yearly}/>
                <h1></h1>
            </React.Fragment>
        ) : null

        const renderTotalStat = !_.isEmpty(this.state.stat.total) ? (
            <React.Fragment>
                <UserStatDetail title={"통산 기록"} isTotal data={{"total": this.state.stat.total}}/>
                <h1></h1>
            </React.Fragment>
        ) : null

        return (
            <div className={"fix-width"}>
                <h1>{`${this.props.match.params.name} 님의 개인 기록`}</h1>
                <h1></h1>
                <div className="ui two column very relaxed grid">
                    <div className="column">
                        <PieChart className={"fix-width-small"}
                                  data={pieChartData}
                                  label={({dataEntry}) => dataEntry.value}
                                  labelStyle={(index) => ({
                                      fill: pieChartData[index].color,
                                      fontSize: '5px',
                                      fontFamily: 'sans-serif',
                                  })}
                                  radius={38}
                                  labelPosition={112}
                                  startAngle={270}
                        />
                    </div>
                    <div className="column">
                        <UserStatSummary
                            isPersonal
                            data={this.state.stat.total}
                            title={`총 국수 : ${this.state.stat.total.match_count}`}
                        />
                        <Link to={`/users/${this.props.match.params.name}/relative`} className="item">
                            <button className={"ui basic black button"}>상대 기록 보러가기!!</button>
                        </Link>
                    </div>
                </div>
                <h1></h1>
                {renderMonthlyList}
                {renderYearlyStat}
                {renderTotalStat}
            </div>
        )
    }
}

export default withRouter(UserStat)
