import React from 'react'
import api from "../../apis/api";
import GameForm from "./GameForm";
import {connect} from "react-redux";
import {signOut} from "../../actions";

class GameEdit extends React.Component {
    state = {game: {}}

    onSubmit = async game => {
        const response = await api.put(`/games/${this.props.match.params.id}`, {'result': game}, {}, this.props.token)
        if (response.status === 401) {
            alert("다시 로그인 해주세요")
            this.props.signOut()
        }
        if (response.status !== 200) {
            alert("수정 실패!")
        } else if (response.data === "Success") {
            alert("수정 성공")
            this.props.history.push("/daily")
        } else {
            alert(response.data)
        }
    }

    render() {
        return (
            <div>
                <h3>게임 수정</h3>
                <GameForm
                    onSubmit={this.onSubmit}
                    gameId={this.props.match.params.id}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(GameEdit)
