import React from "react"
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class DailyRecordDetail extends React.Component {
    listIndividuals = this.props.game.result.map((individual, index) => {
        return (
            <tr key={index}>
                <td>
                    <Link to={`/users/${individual.name}`}>
                        {individual.name}
                    </Link>
                </td>
                <td>{individual.score}</td>
                <td>{individual.victory_point}</td>
                <td>{individual.uma}</td>
                <td>{individual.special}</td>
            </tr>
        )
    })

    render() {
        return (
            <>
                <div className={"ui grid"}>
                    <div className={"four wide column middle aligned"}>
                        <h3>Round {this.props.roundNo}</h3>
                    </div>
                    {
                        this.props.isSignedIn && this.props.role === 'admin' ? (
                            <div className={"twelve wide column"}>
                                <Link
                                    className={"ui right floated negative button"}
                                    to={`/games/delete/${this.props.game.id}`}
                                >
                                    삭제
                                </Link>
                                <Link
                                    className={"ui right floated primary button"}
                                    to={`/games/edit/${this.props.game.id}`}
                                >
                                    수정
                                </Link>
                            </div>
                        ) : null
                    }
                </div>

                <table className={"ui celled unstackable table"}>
                    <thead>
                    <tr>
                        <th>닉네임</th>
                        <th>점수</th>
                        <th>승점</th>
                        <th>우마</th>
                        <th>기타</th>
                    </tr>
                    </thead>
                    <tbody>{this.listIndividuals}</tbody>
                </table>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        role: state.auth.role
    }
}

export default connect(mapStateToProps)(DailyRecordDetail)
