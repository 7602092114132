import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {signOut} from "../actions";
import '../style.css'

const Header = (props) => {
    const auth = useSelector(store => store.auth)
    const dispatch = useDispatch()

    return (
        <div className="ui secondary pointing menu fix-width">
            <Link to="/total" className="item">
                통산
            </Link>
            <Link to="/yearly" className="item">
                연간
            </Link>
            <Link to="/" className="item">
                월간
            </Link>
            <Link to="/weekly" className="item">
                주간
            </Link>
            {
                auth.isSignedIn ? (
                    <Link to="/daily" className="item">
                        일간
                    </Link>
                ) : null
            }
            {
                auth.isSignedIn && auth.role === 'admin' ? (
                    <Link to="/users" className="item">
                        사용자 정보 보기
                    </Link>
                ) : null
            }
            <div className={"right menu"}>
                {
                    auth.isSignedIn && auth.role === 'admin' ? (
                        <Link to="/users/new" className="item">
                            사용자 등록
                        </Link>
                    ) : null
                }
                {
                    auth.isSignedIn && auth.role === 'admin' ? (
                        <Link to="/games/new" className="item">
                            게임 등록
                        </Link>
                    ) : null
                }
                {
                    auth.isSignedIn ? (
                        <button className={"ui button red"} onClick={() => dispatch(signOut())}>
                            로그아웃
                        </button>
                    ) : (
                        <button className={"ui button red"} onClick={() => props.history.push("/login")}>
                            관리자 로그인
                        </button>
                    )
                }
                {/*<Auth />*/}
            </div>
        </div>
    )
}

export default withRouter(Header)
