import React from "react"
import {Link} from "react-router-dom";

const DailyRecordStat = ({recordStats}) => {

    const listStats = recordStats.map((stat, index) => {
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>
                    <Link to={`/users/${stat[0]}`}>
                        {stat[0]}
                    </Link>
                </td>
                <td>{stat[1].score}</td>
                <td>{stat[1].victory_point}</td>
                <td>{stat[1].uma}</td>
                <td>{stat[1].match_count}</td>
            </tr>
        )
    })

    return (
        <>
            <h3>일간 랭킹</h3>
            <table className={"ui celled structured unstackable table"}>
                <thead>
                <tr>
                    <th>순위</th>
                    <th>닉네임</th>
                    <th>점수</th>
                    <th>승점</th>
                    <th>우마</th>
                    <th>국수</th>
                </tr>
                </thead>
                <tbody>{listStats}</tbody>
            </table>
        </>
    )
}

export default DailyRecordStat
