import React from "react"
import api from "../../apis/api";
import UserForm from "./UserForm";
import {connect} from "react-redux";
import {signOut} from "../../actions";

class UserEdit extends React.Component {
    onSubmit = async user => {
        const response = await api.put(`/users/${this.props.match.params.name}`, {
            user_info: user
        }, {}, this.props.token)
        if (response.status === 401) {
            alert("다시 로그인 해주세요")
            this.props.signOut()
        }
        if (response.status !== 200) {
            alert("수정 실패!")
        } else if (response.data === "Success") {
            alert("수정 성공!")
            this.props.history.push("/users")
        } else {
            alert(response.data)
        }
    }

    render() {
        return (
            <div>
                <h3>유저 수정</h3>
                <UserForm
                    onSubmit={this.onSubmit}
                    name={this.props.match.params.name}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit)
