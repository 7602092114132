import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { createStore } from "redux"
import App from "./components/App"
import rootReducer from "./reducers/"
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react"

const store = createStore(rootReducer)
const persistor = persistStore(store)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.querySelector("#root")
)
