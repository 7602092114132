import React, {forwardRef} from "react"
import DailyRecordDetail from "./DailyRecordDetail";
import api from "../../apis/api";
import DailyRecordStat from "./DailyRecordStat";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../../style.css"
import calculateStats from "../../util";
import {connect} from "react-redux";
import {signOut} from "../../actions";

class DailyRecord extends React.Component {
    state = {games: [], recordRows: [], stats: [], statRows: [], date: new Date()}

    fetchGames = async () => {
        const results = await api.get('/games', {
            params: {
                year: this.state.date.getFullYear(),
                month: this.state.date.getMonth() + 1,
                day: this.state.date.getDate()
            }
        }, this.props.token)
        if (results.status === 401) {
            alert("다시 로그인 해주세요")
            this.props.signOut()
        }
        this.setState({games: results.data})
    }

    fillTable = () => {
        this.setState({recordRows: []})
        const listGames = this.state.games.map((game, idx) => {
            return (
                <DailyRecordDetail roundNo={idx + 1} game={game}/>
            )
        })

        const recordRow = []

        listGames.forEach((game, idx) => {
            recordRow.push(
                <div key={idx} className={"column"}>
                    <div className={"ui segment"}>
                        {game}
                    </div>
                </div>
            )
            if (idx % 2 === 1) {
                const recordRowElement = <div key={idx} className={"row"}> {recordRow} </div>
                this.setState({recordRows: [...this.state.recordRows, recordRowElement]})
                recordRow.splice(0, recordRow.length)
            }
        })

        if (recordRow !== []) {
            const recordRowElement = <div key={listGames.length} className={"row"}> {recordRow} </div>
            this.setState({recordRows: [...this.state.recordRows, recordRowElement]})
        }
    }

    calculateStats = () => {
        this.setState({stats: calculateStats(this.state.games)})
    }

    componentDidMount() {
        this.fetchGames().then(() => {
            this.fillTable()
            this.calculateStats()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.date !== this.state.date) {
            this.fetchGames().then(() => {
                this.fillTable()
                this.calculateStats()
            })
        }
    }

    render() {
        const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
            <button className="ui button black basic margin-bottom" onClick={onClick} ref={ref}>
                {value}
            </button>
        ))

        return (
            <div className={"fix-width"}>
                <DatePicker
                    dateFormat="yyyy년 MM월 dd일"
                    selected={this.state.date}
                    onChange={(date) => this.setState({date: date})}
                    customInput={<ExampleCustomInput/>}
                />

                <div>
                    <div className={"ui two column grid"}>
                        {this.state.recordRows}
                    </div>
                    <DailyRecordStat recordStats={this.state.stats}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(DailyRecord)
