import _ from "lodash";

const exampleReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_SORT':
            let data
            if (action.data == null) {
                data = state.data
                if (state.column === action.column) {
                    return {
                        ...state,
                        data: data.slice().reverse(),
                        direction: state.direction === 'ascending' ? 'descending' : 'ascending',
                    }
                }
            } else {
                data = action.data
            }

            switch (action.column) {
                case 'uma':
                case 'average_uma':
                case 'score':
                case 'average_score':
                case 'victory_point':
                case 'average_vp':
                case 'first_ratio':
                case 'half_ratio':
                case 'first_count':
                case 'match_count':
                    return {
                        column: action.column,
                        data: _.sortBy(data, function (obj) {
                            return parseFloat(obj[action.column])
                        }).reverse(),
                        direction: 'ascending',
                    }
                case 'average_rank':
                case 'last_ratio':
                    return {
                        column: action.column,
                        data: _.sortBy(data, function (obj) {
                            return parseFloat(obj[action.column])
                        }),
                        direction: 'ascending'
                    }
                default:
                    return {
                        column: action.column,
                        data: _.sortBy(data, [action.column]),
                        direction: 'ascending',
                    }
            }

        default:
            throw new Error()
    }
}

export default exampleReducer
