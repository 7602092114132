import React from "react"
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import MonthlyRecord from "./record/MonthlyRecord";
import DailyRecord from "./record/DailyRecord";
import Header from "./Header";
import GameCreate from "./game/GameCreate";
import {useSelector} from "react-redux";
import UserCreate from "./user/UserCreate";
import Auth from "./Auth";
import GameEdit from "./game/GameEdit";
import GameDelete from "./game/GameDelete";
import WeeklyRecord from "./record/WeeklyRecord";
import UserManage from "./user/UserManage";
import UserEdit from "./user/UserEdit";
import UserStat from "./user/UserStat";
import YearlyRecord from "./record/YearlyRecord";
import TotalRecord from "./record/TotalRecord";
import UserRelativeStat from "./user/UserRelativeStat";

const App = () => {
    const auth = useSelector(store => store.auth)

    return (
        <div className={"ui container"}>
            <BrowserRouter>
                <Header/>
                <Switch>
                    <Route path={"/"} exact component={MonthlyRecord}/>
                    <Route path={"/total"} exact component={TotalRecord}/>
                    <Route path={"/yearly"} exact component={YearlyRecord}/>
                    <Route path={"/weekly"} exact component={WeeklyRecord}/>
                    <Route path={"/daily"} exact component={DailyRecord}>
                        {auth.isSignedIn ? <DailyRecord/> : <Redirect to="/"/>}
                    </Route>
                    <Route path={"/users"} exact component={UserManage}>
                        {auth.isSignedIn && auth.role === 'admin' ? <UserManage/> : <Redirect to="/"/>}
                    </Route>
                    <Route path={"/users/new"} exact>
                        {auth.isSignedIn && auth.role === 'admin' ? <UserCreate/> : <Redirect to="/"/>}
                    </Route>
                    <Route path={"/users/:name"} exact component={UserStat} />
                    <Route path={"/users/:name/relative"} exact component={UserRelativeStat} />
                    {
                        auth.isSignedIn && auth.role === 'admin'
                            ? <Route path={"/users/edit/:name"} exact component={UserEdit}/>
                            : null
                    }
                    <Route path={"/games/new"} exact>
                        {auth.isSignedIn && auth.role === 'admin' ? <GameCreate/> : <Redirect to="/"/>}
                    </Route>
                    {
                        auth.isSignedIn && auth.role === 'admin'
                            ? <Route path={"/games/edit/:id"} exact component={GameEdit}/>
                            : null
                    }
                    {
                        auth.isSignedIn && auth.role === 'admin'
                            ? <Route path={"/games/delete/:id"} exact component={GameDelete}/>
                            : null
                    }
                    <Route path={"/login"} exact>
                        {!auth.isSignedIn ? <Auth/> : <Redirect to={"/"}/>}
                    </Route>
                    <Redirect path={"*"} to={"/"}/>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App;
