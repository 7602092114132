import React from 'react'
import _ from "lodash";
import {Table, TableCell, TableRow} from "semantic-ui-react";

class UserStatSummary extends React.Component {
    render() {
        const averageUma = this.props.isPersonal ? (
            !_.isEmpty(this.props.data) ?
                Number(this.props.data.uma / this.props.data.match_count).toFixed(2)
                : 0
        ) : !_.isEmpty(this.props.data) ? this.props.data.average_uma.toFixed(2) : 0
        const firstRatio = !_.isEmpty(this.props.data)
            ? Number(this.props.data.first_count / this.props.data.match_count * 100).toFixed(2)
            : 0
        const secondRatio = !_.isEmpty(this.props.data)
            ? Number(this.props.data.second_count / this.props.data.match_count * 100).toFixed(2)
            : 0
        const thirdRatio = !_.isEmpty(this.props.data)
            ? Number(this.props.data.third_count / this.props.data.match_count * 100).toFixed(2)
            : 0
        const fourthRatio = !_.isEmpty(this.props.data)
            ? Number(this.props.data.fourth_count / this.props.data.match_count * 100).toFixed(2)
            : 0

        return !_.isEmpty(this.props.data) ? (
            <React.Fragment>
                {
                    this.props.isPersonal ? <h1/> : <h3 />
                }
                <h2>{this.props.title}</h2>
                <h2/>
                <Table celled unstackable padded>
                    <Table.Body>
                        <TableRow>
                            <TableCell active>우마</TableCell>
                            <TableCell>{Number(this.props.data.uma).toFixed(1)} </TableCell>
                            <TableCell active>평우마</TableCell>
                            <TableCell>{averageUma}</TableCell>
                        </TableRow>
                        {
                            !this.props.isPersonal
                                ? (
                                    <TableRow>
                                        <TableCell active>평순위</TableCell>
                                        <TableCell>{this.props.data.average_rank.toFixed(2)} </TableCell>
                                        <TableCell active>평득점</TableCell>
                                        <TableCell>{this.props.data.average_score.toFixed(0)}</TableCell>
                                    </TableRow>
                                ) : null
                        }
                        <TableRow>
                            <TableCell active>1위</TableCell>
                            <TableCell>{this.props.data.first_count} </TableCell>
                            <TableCell active>1위율</TableCell>
                            <TableCell>{firstRatio} %</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell active>2위</TableCell>
                            <TableCell>{this.props.data.second_count} </TableCell>
                            <TableCell active>2위율</TableCell>
                            <TableCell>{secondRatio} %</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell active>3위</TableCell>
                            <TableCell>{this.props.data.third_count} </TableCell>
                            <TableCell active>3위율</TableCell>
                            <TableCell>{thirdRatio} %</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell active>4위</TableCell>
                            <TableCell>{this.props.data.fourth_count} </TableCell>
                            <TableCell active>4위율</TableCell>
                            <TableCell>{fourthRatio} %</TableCell>
                        </TableRow>
                    </Table.Body>
                </Table>
            </React.Fragment>
        ) : null
    }
}

export default UserStatSummary