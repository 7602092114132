import React from 'react'
import Modal from "../Modal";
import {Link, withRouter} from "react-router-dom";
import api from "../../apis/api";
import {connect} from "react-redux";
import {compose} from "redux";
import {signOut} from "../../actions";

class GameDelete extends React.Component {
    onClick = async id => {
        const response = await api.del(`/games/${id}`, {}, this.props.token)
        if (response.status === 401) {
            alert("다시 로그인 해주세요")
            this.props.signOut()
        }
        if (response.status !== 200) {
            alert("삭제 실패!")
        } else if (response.data === "Success") {
            alert("삭제 성공!")
            this.props.history.push("/daily")
        } else {
            alert(response.data)
        }
    }

    actions = (
        <React.Fragment>
            <button
                onClick={() => this.onClick(this.props.match.params.id)}
                className={"ui button negative"}
            >
                삭제
            </button>
            <Link className={"ui button"} to={"/daily"}>취소</Link>
        </React.Fragment>
    )

    render() {
        return (
            <div>
                <Modal
                    title={"게임 삭제"}
                    content={"지우시겠습니까?"}
                    actions={this.actions}
                    onDismiss={() => this.props.history.push("/daily")}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(GameDelete)
