import React from 'react'
import api from "../../apis/api";
import UserDetail from "./UserDetail";
import "../../style.css"
import {connect} from "react-redux";
import {signOut} from "../../actions";

class UserManage extends React.Component {
    state = {users: [], userRows: []}

    fetchUsers = async () => {
        const results = await api.get('/users', {}, this.props.token)
        if (results.status === 401) {
            alert("다시 로그인 해주세요")
            this.props.signOut()
        }
        this.setState({users: results.data})
    }

    fillTable = () => {
        this.setState({userRows: []})
        const listUsers = this.state.users.map(user => {
            return (
                <UserDetail key={user.name} user={user} />
            )
        })
        this.setState({userRows: listUsers})
    }

    componentDidMount() {
        this.fetchUsers().then(() => {
            this.fillTable()
        })
    }

    render() {

        return (
            <div className={"fix-width"}>
                <div className={"ui three stackable cards"}>
                    {this.state.userRows}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserManage)
