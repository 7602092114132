import React, {useEffect, useState} from "react"
import exampleReducer from "../../reducers/exampleReducer";
import api from "../../apis/api";
import calculateStats from "../../util";
import RecordTable from "./RecordTable";
import {Dropdown} from "semantic-ui-react";
import {yearOption} from "./Option";

const YearlyRecord = () => {
    const [games, setGames] = useState([])
    const [stats, setStats] = useState([])
    const [year, setYear] = useState(new Date().getFullYear())
    const [listStats, setListStats] = useState([])
    const [filter, setFilter] = useState(0)

    const [state, dispatch] = React.useReducer(exampleReducer, {
        column: null,
        data: listStats,
        direction: null,
    })
    const {column, data, direction} = state

    const fetchGames = async () => {
        const results = await api.get('/games/yearly', {
            params: {
                year: year,
            }
        })
        if (results.status === 200) {
            setGames(results.data)
        } else {
            setGames([])
        }
    }

    useEffect(() => {
        fetchGames()
    }, [year])

    useEffect(() => {
        setStats(calculateStats(games))
    }, [games])

    useEffect(() => {
        setListStats(stats.map(stat => {
            const [name, {
                uma,
                score,
                victory_point,
                first_count,
                second_count,
                third_count,
                fourth_count,
                match_count
            }] = stat
            return (
                {
                    name: name,
                    uma:  Number(uma).toFixed(1),
                    average_uma: Number(uma / match_count).toFixed(2),
                    score: Number(score).toFixed(0),
                    average_score: Number(score / match_count).toFixed(0),
                    victory_point: Number(victory_point).toFixed(0),
                    average_vp: Number(victory_point / match_count).toFixed(2),
                    average_rank: Number((first_count + second_count * 2 + third_count * 3 + fourth_count * 4) / match_count).toFixed(2),
                    first_ratio: Number(first_count / match_count * 100).toFixed(1),
                    half_ratio: Number((first_count + second_count) / match_count * 100).toFixed(1),
                    last_ratio: Number(fourth_count / match_count * 100).toFixed(1),
                    first_count: first_count,
                    second_count: second_count,
                    third_count: third_count,
                    fourth_count: fourth_count,
                    match_count: match_count
                }
            )
        }).filter(stat => stat.match_count >= filter))
    }, [stats, filter])

    useEffect(() => {
        dispatch({type: 'CHANGE_SORT', column: column, data: listStats})
    }, [listStats])

    const onChange = e => {
        numberCheck(e.target.value)
    }

    const numberCheck = v => {
        let num = v || 0
        if (!isFinite(num)) return
        num = num.toString()

        if (num !== '0' && !num.includes('.')) {
            num = num.replace(/^0+/, '')
        }

        setFilter(num)
    }

    const onChangeYear = (e, data) => {
        setYear(data.value)
    }

    const YearDropdown = () => (
        <Dropdown
            placeholder="Select a year"
            fluid
            selection
            options={yearOption}
            onChange={onChangeYear}
            defaultValue={year}
        />
    )

    return (
        <div className={"fix-width"}>
            <div className={"ui input"}>
                <YearDropdown />
            </div>
            <h3>기준 국수</h3>
            <div className={"ui input"}>
                <input type={"text"} value={filter} onChange={onChange}/>
            </div>
            <h3>{year}년 연간 랭킹</h3>
            <RecordTable column={column} data={data} direction={direction} dispatch={dispatch}/>
        </div>
    )
}

export default YearlyRecord
