import React from "react"
import api from "../../apis/api";
import {withRouter} from "react-router-dom";
import "../../style.css"
import UserForm from "./UserForm";
import {connect} from "react-redux";
import {compose} from "redux";
import {signOut} from "../../actions";

class UserCreate extends React.Component {
    onSubmit = async user => {
        const response = await api.post('/users', {
            user_info: user
        }, {}, this.props.token)
        if (response.status === 401) {
            alert("다시 로그인 해주세요")
            this.props.signOut()
        }
        if (response.status !== 200) {
            alert("등록 실패!")
            return false
        } else if (response.data === "Success") {
            alert("등록 성공!")
            return true
        } else {
            alert(response.data)
            return false
        }
    }

    render() {
        return (
            <div className={"fix-width"}>
                <h3>유저 등록</h3>
                <UserForm onSubmit={this.onSubmit}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(UserCreate)
